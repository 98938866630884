/* utilityクラスは必ずu始まり */

.u-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.u-absolute-y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-mt-4 {
  margin-top: 4px;
}
.u-ml-10 {
  margin-left: 10px;
}

.u-text-xxxs {
  /* font-size: 8px */
  font-size: 0.5rem;
}
.u-text-xxs {
  /* font-size: 10px */
  font-size: 0.625rem;
}
.u-wrapper-side {
  padding-right: 16px;
  padding-left: 16px;
}
.u-wrapper-side-margin {
  margin-right: 16px;
  margin-left: 16px;
}
.u-wrapper-top {
  padding-top: 32px;
}

.u-line-clump-2 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.u-full-width-background {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    right: 50%;
    width: 100vw;
    z-index: 1;
    margin-right: -50vw;
    margin-left: -50vw;
  }
}

.u-pt-full {
  padding-top: 100%;
}
.u-pt-60 {
  padding-top: 60%;
}
.u-w-22 {
  width: 88px;
}

.u-h-full {
  height: calc(100vh - 100px);
}